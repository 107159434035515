<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <label
        class="block text-sm truncate text-gray"
        :class="{ 'text-error': error, 'mb-1': label }"
      >
        {{ label }} <span v-if="mandatory" class="text-error">*</span>
      </label>
    </div>
    <div
      class="items-center pl-0 border rounded-lg"
      :class="[
        {
          ' grid grid-cols-4 border':
            this.$slots['select-item-left'] != undefined ||
            this.$slots['select-item-right'],
        },
        {
          'flex':
            this.$slots['select-item-left'] == undefined ||
            this.$slots['select-item-right'] == undefined,
        },
        { 'border-secondary shadow-secondary-round hover:shadow-secondary-round': isFocus },
        { 'bg-gray-lightest': this.disabled },
        { 'border-primary hover:shadow-primary-sm ': !error && !this.disabled },
        { 'border-gray-lightest': this.disabled },
        { 'border-error hover:shadow-primary-sm': error },
        {
          'text-xs h-9': this.size == 'tiny',
          'text-sm h-11': this.size == 'small',
          'text-base h-12': this.size == 'medium',
          'text-base h-14': this.size == 'large',
        },
        // {
        //   'ml-1 mr-1':
        //     this.$slots['icon-left'] == undefined &&
        //     this.$slots['icon-right'] == undefined &&
        //     this.$slots['select-item'] == undefined,
        // },
        // {
        //   'ml-1 mr-1':
        //     this.$slots['icon-left'] != undefined
        //       ? this.$slots['icon-left'][0].children == undefined
        //         ? true
        //         : false
        //       : false,
        // },
        // {
        //   'ml-1 mr-2':
        //     this.$slots['icon-right'] != undefined
        //       ? this.$slots['icon-right'][0].children == undefined
        //         ? true
        //         : false
        //       : false,
        // },
      ]"
    >
      <!-- slot untuk icon left -->
      <div
        class="inset-y-0 left-0 flex items-center pl-2"
        v-if="this.$slots['icon-left'] != undefined"
        :class="[
          {
            'text-base': this.size == 'tiny',
            'text-base': this.size == 'small',
            'text-2xl': this.size == 'medium',
            'text-2xl': this.size == 'large',
            'cursor-pointer':
              this.$slots['icon-left'] != undefined
                ? this.$slots['icon-left'][0].data.on != undefined
                : false,
          },
        ]"
      >
        <slot name="icon-left"></slot>
      </div>
      <!-- end-->

      <!-- slot untuk select -->
      <div
        class="inset-y-0 left-0 items-center w-full col-span-1 pl-0"
        v-if="this.$slots['select-item-left'] != undefined"
        :class="[
          {
            'text-base': this.size == 'tiny',
            'text-base': this.size == 'small',
            'text-2xl': this.size == 'medium',
            'text-2xl': this.size == 'large',
          },
        ]"
      >
        <slot name="select-item-left"></slot>
      </div>
      <input
        @input="handleInput"
        v-model="computedValue"
        :placeholder="placeholder"
        :type="type"
        class="w-full h-full border-none rounded-lg focus:ring-0 placeholder-gray-light text-gray"
        @focus="focusInput"
        @blur="blurInput"
        @keypress="keypress"
        @keydown="keydown"
        :min="min"
        :class="[
          { 'text-center': this.align == 'center'},
          { 'bg-gray-lightest text-gray-darkest': this.disabled },
          { 'cursor-not-allowed': this.disabled || this.readonly },
          { 'col-span-2': this.$slots['select-item'] != undefined },
        ]"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
      />
      <div
        class="inset-y-0 right-0 flex items-center pr-2 text-base text-center"
        v-if="this.$slots['icon-right'] != undefined"
        :class="[
          {
            'text-2xl':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].data.class != undefined
                  ? true
                  : false
                : false,
            'cursor-pointer':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].data.on != undefined
                : false,
            'bg-primary text-white rounded-r-lg w-28 h-11':
              this.$slots['icon-right'] != undefined
                ? this.$slots['icon-right'][0].children != undefined
                  ? true
                  : false
                : false,
          },
        ]"
      >
        <slot name="icon-right"></slot>
      </div>
      <div
        class="inset-y-0 right-0 items-center w-full col-span-1 pr-0 border-border-none"
        v-if="this.$slots['select-item-right'] != undefined"
        :class="[
          {
            'text-2xl': this.size == 'small',
            'text-2xl': this.size == 'large',
          },
        ]"
      >
        <slot name="select-item-right"></slot>
      </div>
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="mt-1 text-xs"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>
<script>
import { cleanedInput } from '@/core/components/helpers';
/*
  <input-gista
    v-validate="'required|max:255'"
    :error="errors.has('vendor_name')"
    data-vv-as="Nama Vendor"
    name="vendor_name"
    size="large"
    placeholder="isi Nama Vendor"
    type="text"
    v-model="form.vendor_name"
    label="Nama Vendor*"
    :disabled="true"
  >
    <div v-if="errors.has('vendor_name')" slot="message">
      {{ errors.first("vendor_name") }}
    </div>
    <div v-if="!form.vendor_name" slot="message" class="text-gray">
      Wajib isi kode vendor terlebih dahulu
    </div>
  </input-gista>
*/

export default {
  name: "InputSolutip",
  props: {
    // untuk v-model
    value: [String, Number],
    // for font size, use large
    size: String,
    placeholder: String,
    // for input type
    type: String,
    // error flag
    error: Boolean,
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    // input ref
    // ref: String,
    // don't use
    removeWhiteSpace: Boolean,
    min: Number,
    align: {
      type: String,
      default: 'left'
    },
    mandatory: Boolean,
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    focusInput() {
      this.isFocus = true
      this.$emit('focus')
    },
    blurInput() {
      this.isFocus = false
      this.computedValue = cleanedInput(this.computedValue)
      this.$emit('blur')
    },
    keypress(e) {
      this.$emit('keypress', e)
    },
    keydown(e) {
      this.$emit('keydown', e)
    },
    disabledWhiteSpace(e) {
      if (this.removeWhiteSpace) {
        if (e.keyCode == 32 || e.keyCode == 86 || e.keyCode == 17) {
          e.preventDefault();
          return;
        }
      }
    },
    handleInput(e) {
      this.$emit('handleInput', e)
    },
  },
};
</script>
