<template>
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-6">
    <div class="w-full p-3 rounded-lg shadow-gray-sm">
      <div class="flex gap-3.5 mb-6">
        <h3 class="flex-auto text-sm lg:text-base">RFX</h3>
				<div class="flex-none">
					<SelectChart size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectRfx" :options="dateListOption" @click="changeYear($event, 'rfx', 'selectRfx')">
						<template #selected>
							{{ selectRfx || 'Select' }}
						</template>
					</SelectChart>
				</div>
      </div>
      <LoadingSpinner v-if="cardLoading.rfx" class="p-28"/>
      <VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfx.startYear" :endYear="rfx.endYear" :dataApi="chartDataRFX"  :fontSize="8"></VueChart>
    </div>
    <div class="w-full p-3 rounded-lg shadow-gray-sm">
      <div class="flex gap-3.5 mb-6">
        <h3 class="flex-auto text-sm lg:text-base">RFP</h3>
				<div class="flex-none">
					<SelectChart size="small" iconLeft="icon-filter text-gray" :value="selectRfp" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfp', 'selectRfp')">
						<template #selected>
							{{ selectRfp || 'Select' }}
						</template>
					</SelectChart>
				</div>
      </div>
      <LoadingSpinner v-if="cardLoading.rfp" class="p-28"/>
      <VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfp.startYear" :endYear="rfp.endYear" :dataApi="chartDataRFP" :fontSize="8"></VueChart>
    </div>
    <div class="w-full p-3 rounded-lg shadow-gray-sm">
      <div class="flex gap-3.5 mb-6">
        <h3 class="flex-auto text-sm lg:text-base">RFQ</h3>
				<div class="flex-none">
					<SelectChart size="small" iconLeft="icon-filter text-gray" :value="selectRfq" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfq', 'selectRfq')">
						<template #selected>
							{{ selectRfq || 'Select' }}
						</template>
					</SelectChart>
				</div>
      </div>
      <LoadingSpinner v-if="cardLoading.rfq" class="p-28"/>
      <VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfq.startYear" :endYear="rfq.endYear" :dataApi="chartDataRFQ" :fontSize="8"></VueChart>
    </div>
  </div>
</template>

<script>
import VueChart from '@/core/components/dashboard/VueChart.vue'
import SelectChart from '@/core/components/custom/SelectChart.vue';
import LoadingSpinner from '@/core/components/custom/LoadingSpinner.vue';

export default {
	name: "TotalRfx",
	data() {
		return {
			rfx: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfp: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfq: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			cardLoading: {
				rfx: true,
				rfp: true,
				rfq: true,
			},
			selectRfx: '',
			selectRfp: '',
			selectRfq: '',
		}
	},
	computed: {
		chartDataRFX() {
			return this.$store.state.report.chartDataRFX
		},
		chartDataRFP() {
			return this.$store.state.report.chartDataRFP
		},
		chartDataRFQ() {
			return this.$store.state.report.chartDataRFQ
		},
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
		dateListOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear-3, dateGetYear-2, dateGetYear-1, dateGetYear ]
		},
	},
	methods: {
		async changeYear(e, key, select) {
			const startYear = e.startYear
			const endYear = e.endYear
			this[key].startYear = startYear
			this[key].endYear = endYear
			this[select] = `${e.startYear} - ${e.endYear}`
			this.cardLoading[key] = true
			await this.$store.dispatch('report/getReportChart', { startYear: startYear, endYear: endYear, moduleName: key})
			this.cardLoading[key] = false
		},
	},
	mounted() {
		this.changeYear({ startYear: this.rfx.startYear,  endYear: this.rfx.endYear }, 'rfx', 'selectRfx')
		this.changeYear({ startYear: this.rfp.startYear,  endYear: this.rfp.endYear }, 'rfp', 'selectRfp')
		this.changeYear({ startYear: this.rfq.startYear,  endYear: this.rfq.endYear }, 'rfq', 'selectRfq')
	},
	components: { 
		VueChart, 
		SelectChart,
		LoadingSpinner
	}
}
</script>