<template>
	<div>
		<Card class="w-full">
			<div class="w-full">
				<div class="flex justify-between items-center mb-6 sm:flex-row flex-col">
					<h4 class="sm:mr-6 text-3xl font-semibold flex-start sm:w-max w-full gap-3.5">Vendors</h4>
					<CreateRfi />
				</div>
				<div class="flex justify-between mb-6 sm:flex-row flex-col gap-3.5">
					<select-dashboard class="md:w-72 flex-grow md:flex-grow-0 mr-6 sm:w-max w-full"
						label="Sort:"
						placeholder="All"
						@change="changeSort"
						:options="['No RFI: Newest', 'No RFI: Oldest', 'ITP 01 status: All', 'ITP 01 status: Updated','ITP 01 status: Not Uploaded', 'ITP 01 status: Expired']">
					</select-dashboard>
					<InputGista type=text
						size="large"
						placeholder="Search"
						v-model="search"
						class="md:w-96 flex-grow md:flex-grow-0">
						<template #icon-left>
							<span class="icon-search text-primary text-xl"></span>
						</template>
					</InputGista>
				</div>
				<div v-if="menuListVendors?.canRead">
					<TableGista :items="vendorRfiList?.vendorList"
						:headers="headers"
						:sort-order="sortParams"
						:options.sync="queryParams"
						:show-pagination="true"
						:searchable="false"
						:server-side="true"
						:row-class="(data, i) => data?.itp01Status === 'Expired'? 'bg-gray-lightest' : i % 2 === 0? 'bg-primary-lightest bg-opacity-25' : 'bg-white'"
						class="mt-6"
						@update:options="updatePagination"
						@refresh="fetchData">
						<template v-slot:no="{ slotProps }">
							{{ slotProps.index + 1 }}
						</template>
						<template v-slot:action="{ slotProps }">
							<ButtonGista 
                @click="toggleView(slotProps.data)"
                size="small"
                customClass="rounded-full">View</ButtonGista>
						</template>
					</TableGista>
				</div>
			</div>
		</Card>
		<GistaModal 
			:value="modalVendorDetail"
			width="2/3"
			scrollable
			@input="closeModal"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<VendorDetail :vendor="vendor" @closeModal="closeModal"></VendorDetail>
		</GistaModal>
	</div>
</template>

<script>
import SelectDashboard from "@/core/components/custom/SelectDashboard.vue";
import CreateRfi from "@/core/components/vendors/CreateRfi.vue";
import Card from '@/core/components/custom/Card.vue'
import VendorDetail from '@/core/components/vendors/VendorDetail'

export default {
	name: "Vendors",
	data() {
		return {
			isOpen: false,
			search: '',
			sort: '',
			itp01: '',
			timeout: null,
			isLoadingTable: true,
			headers: [
				{
					value: "no",
					text: "No",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "noRfi",
					text: "No. Request",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "vendorName",
					text: "Vendor Name",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "itp01Status",
					text: "ITP 01 Status",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "action",
					text: "Action",
					clickable: false,
					sortable: false,
					align: "start",
				},

			],
			sortParams: [
			],
			queryParams: {
				totalItems: 0,
				itemsPerPage: 10,
				page: 0
			},
			modalVendorDetail: false,
			vendor: null
		}
	},
	computed: {
		menuListVendors() {
			return this.$store.getters['dashboard/menuListVendors']
		},
		vendorRfiList() {
      return this.$store.state.rfi.vendorRfiList
    },
	},
	watch: {
		search() {
			this.searchFetch()
		},
		vendorRfiList() {
			this.queryParams = {
				totalItems: this.vendorRfiList?.totalElements,
				itemsPerPage: this.vendorRfiList?.pageSize,
				page: this.vendorRfiList?.pageNumber + 1
			}
		}
	},
	methods: {
		searchFetch() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(async () => {
				this.fetchData()
			}, 500)
		},
		async changeSort(e) {
			console.log(e);
			if (e === 'No RFI: Newest') {
				this.sort = 'noRfi,desc'
				this.itp01 = ''
				this.fetchData()
			} else if (e === 'No RFI: Oldest') {
				this.sort = 'noRfi,asc'
				this.itp01 = ''
				this.fetchData()
			}	else if (e === 'ITP 01 status: All') {
				this.sort = ''
				this.itp01 = ''
				this.fetchData()
			} else if (e === 'ITP 01 status: Updated') {
				this.itp01 = 'UPDATED'
				this.sort = ''
				this.fetchData()
			} else if (e === 'ITP 01 status: Not Uploaded') {
				this.itp01 = 'NOT_UPLOADED'
				this.sort = ''
				this.fetchData()
			} else if (e === 'ITP 01 status: Expired') {
				this.itp01 = 'EXPIRED'
				this.sort = ''
				this.fetchData()
			}
		},
		changeIsLoadingTable(e) {
			this.isLoadingTable = e
		},
		async fetchData() {
			this.isLoadingTable = true
			await this.$store.dispatch('rfi/getVendorRfiList', { page: this.queryParams.page, size: this.queryParams.itemsPerPage, anySearch: this.search, sort: this.sort, itp01: this.itp01 })
			this.isLoadingTable = false
		},
		updatePagination(e) {
			this.queryParams = {
				page: e.page,
				itemsPerPage: e.itemsPerPage,
				totalItems: e.totalItems,
			}
		},
		toggleView(vendor) {
      this.vendor = vendor
			this.modalVendorDetail = !this.modalVendorDetail
    },
		closeModal() {
      this.modalVendorDetail = !this.modalVendorDetail
    },
	},

	async mounted() {
		this.sort = 'noRfi,desc'
		this.fetchData()

	},
	components: {
    SelectDashboard,
    CreateRfi,
    Card,
		VendorDetail
},
}
</script>