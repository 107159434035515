var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{class:`flex flex-col justify-center p-2 rounded-lg bg-white border border-primary hover:shadow-primary-sm`},[_c('div',{staticClass:"relative text-sm"},[_c('button',{staticClass:"flex items-center rounded-md",class:{
          'text-base': _vm.size !== 'large',
          'h-12': (_vm.size === 'large'),
        },on:{"click":function($event){_vm.isOptionsExpanded = !_vm.isOptionsExpanded}}},[_c('div',{staticClass:"flex items-center pr-3 pointer-events-none"},[_c('span',{class:_vm.iconLeft})]),_c('div',{staticClass:"pr-3"},[_c('label',{staticClass:"flex items-center text-xs text-gray",class:{ 'text-error': _vm.error }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('p',{staticClass:"text-gray-dark truncate",class:{
              'text-lg': _vm.size === 'large',
              'text-sm': _vm.size === 'extra-small',
              'text-base': !_vm.size,
            }},[_vm._t("selected",function(){return [_vm._v(_vm._s(_vm.selected || _vm.placeholder))]},{"slotProps":_vm.selected})],2)]),_c('svg',{staticClass:"w-4 h-4 transition-transform duration-200 ease-in-out transform",class:_vm.isOptionsExpanded ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])])]),_c('transition',{attrs:{"enter-active-class":"transition duration-500 transform ease-custom","enter-class":"scale-y-0 -translate-y-1/2 opacity-0","enter-to-class":"scale-y-100 translate-y-0 opacity-100","leave-active-class":"transition duration-300 transform ease-custom","leave-class":"scale-y-100 translate-y-0 opacity-100","leave-to-class":"scale-y-0 -translate-y-1/2 opacity-0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOptionsExpanded),expression:"isOptionsExpanded"}],staticClass:"absolute left-0 right-0 z-30 px-3 overflow-hidden bg-white rounded-md shadow-primary-sm"},[_c('div',_vm._l((_vm.options),function(v,i){return _c('div',{key:i},[_c('GistaCheckbox',{staticClass:"text-gray",class:{ 'text-lg': _vm.size === 'large', 'text-sm': _vm.size == 'extra-small', 'text-base': !_vm.size,},attrs:{"size":"small","modelValue":_vm.modelValue[i]?.value},on:{"change":function($event){return _vm.changeCheckBox($event, i)}}},[_vm._v(_vm._s(v))])],1)}),0),_c('div',{staticClass:"w-full mb-3"},[_c('button-gista',{attrs:{"size":"small","customClass":"w-full","disabled":_vm.disabledApply},on:{"click":_vm.clickApply}},[_vm._v("Apply")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }