<template>
	<Card class="w-full mb-6" padding="p-3.5 sm:p-6">
		<div class="flex w-full gap-x-6"
			:class="{ 'flex-wrap': windowWidth < 1400 }">
			<div class="flex flex-wrap items-center mb-6">
				<h4 class="flex text-3xl font-semibold">Projects</h4>
			</div>
			<div class="flex w-full"
				:class="`${menuDraft?.canRead? windowWidth < 1250? 'flex-wrap': 'justify-between' : windowWidth < 1024? 'flex-wrap': 'justify-between'}`">
				<div v-if="menuDraft?.canRead"
					class="flex mb-6 flex-nowrap lg:justify-end"
					:class="{ 'w-full': windowWidth < 1250, 'min-w-custom': windowWidth >= 1250 }">
					<ThreeButton :options="['Draft', 'Outstanding', 'Completed']"
						@click="changeActiveButton"
						:value="activeButton" />
				</div>
				<div v-else
					class="flex mb-6 flex-nowrap lg:justify-end"
					:class="{ 'w-full': windowWidth < 1024, 'min-w-custom': windowWidth >= 1024 }">
					<ThreeButton :options="['Outstanding', 'Completed']"
						@click="changeActiveButton"
						:value="activeButton" />
				</div>

				<div class="gap-6 sm:flex sm:flex-nowrap lg:justify-end"
					:class="{ 'w-full': windowWidth < 1250, 'w-1/2': windowWidth >= 1250 }">
					<div class="w-full"
						:class="{ 'w-max': windowWidth >= 1250, 'mb-6': menuRFQ?.canCreate && menuRFP?.canCreate }">
						<router-link :to="`/${PATH_ROOT}/rfq`">
							<ButtonGista color="secondary"
								v-if="menuRFQ?.canCreate"
								customClass="w-full">
								Create New Quotation
							</ButtonGista>
						</router-link>
					</div>
					<div class="w-full"
						:class="{ 'w-max': windowWidth >= 1250, 'mb-6': menuRFQ?.canCreate && menuRFP?.canCreate }">
						<router-link :to="`/${PATH_ROOT}/rfp`">
							<ButtonGista 
								v-if="menuRFP?.canCreate"
								customClass="w-full">
								Create New Project
							</ButtonGista>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<Outstanding v-if="activeButton === 'Outstanding' && !loading.loadingDashboard"
			:windowWidth="windowWidth" />
		<Draft v-if="activeButton === 'Draft' && menuDraft?.canRead && !loading.loadingDashboard"
			:windowWidth="windowWidth" />
		<Completed v-if="activeButton === 'Completed' && !loading.loadingDashboard"
			:windowWidth="windowWidth"  />
		<ProjectLoading v-if="loading.loadingDashboard"/>
	</Card>
</template>
<script>
import Outstanding from '@/core/components/dashboard/Outstanding.vue'
import Draft from '@/core/components/dashboard/Draft.vue';
import Completed from '@/core/components/dashboard/Completed.vue';
import Card from '@/core/components/custom/Card.vue'
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import { PATH_ROOT } from '@/core/constant/routeName'
import ProjectLoading from '@/core/components/dashboard/ProjectLoading.vue';
import { capitalizeFirstLetter } from '@/core/components/helpers/index.js'


export default {
	name: "Projects",
	props: ['windowWidth'],
	data() {
		return {
			PATH_ROOT,
		};
	},
	computed: {
		activeButton: {
			get() {
				return this.$store.state.projectLog.activeButton
			},
			set(value) {
				this.$store.commit('projectLog/SET_ACTIVE_BUTTON', value)
			}
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		menuRFP() {
			return this.$store.getters['dashboard/menuRFP']
		},
		menuRFQ() {
			return this.$store.getters['dashboard/menuRFQ']
		},
		menuDraft() {
			return this.$store.getters['dashboard/menuDraft']
		},
	},
	methods: {
		capitalizeFirstLetter,
		clickStatusProject(value) {
			this.activeButton = value;
		},
		changeActiveButton(e) {
			this.activeButton = e
			this.$store.dispatch('dashboard/resetFilterProject')
			this.$router.replace({ name: 'Home', query: { project: e.toLowerCase() }})
		},
	},
	mounted() {
		if (this.$route.query.project) {
			this.activeButton = this.capitalizeFirstLetter(this.$route.query.project)
		}
	},
	components: {
		Outstanding,
		Draft,
		Completed,
		Card,
		ThreeButton,
		ProjectLoading
	}
}
</script>

<style scoped>
.min-w-custom {
	min-width: 30rem;
}
</style>