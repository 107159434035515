<template>
  <div ref="divCanvas">
    <Bar 
      :chart-data="chartData" 
      :chart-options="chartOptions" 
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"/>
  </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs/legacy'

  import { Chart,
    BarElement,
    PointElement,
    BarController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle } from 'chart.js'
  Chart.register(
    BarElement,
    PointElement,
    BarController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle);
  
    export default {
      name: 'VueChart',
      props: {
        chartId: {
          type: String,
          default: 'bar-chart'
        },
        datasetIdKey: {
          type: String,
          default: 'label'
        },
        width: {
          type: Number,
          default: 400
        },
        height: {
          type: Number,
          default: 250
        },
        cssClasses: {
          default: '',
          type: String
        },
        styles: {
          type: Object,
          default: () => ({})
        },
        plugins: {
          type: Array,
          default: () => [{
            beforeInit: function (chart) {
              chart.data.labels.forEach(function (e, i, a) {
                if (/\n/.test(e)) {
                  a[i] = e.split(/\n/)
                }
              })
            }
          }]
        },
        dataApi: {
          type: Array,
          default: () => []
        },
        backgroundColor: {
          type: String,
          default: '#30D5C9'
        },
        datalabel: {
          type: Boolean,
          default: false,
        },
        rotationDataLabels: {
          type: Number,
          default: 0,
        },
        dataSetKey: {
          type: String,
          default: 'slaCount'
        },
        labelKey: {
          type: String,
          default: 'name'
        },
        fontSize: {
          type: Number,
          default: 10
        }
      },
      data() {
        return {
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            lineTension: 1,
            plugins: {
              legend: {
                  display: false 
              },
              datalabels: {
                  display: this.datalabel,
                  formatter: (value) => {
                      return value;
                  },
                  anchor: 'end',
                  font: {
                    size: this.fontSize
                  },
                  align: 'end',
                  offset: 1,
                  clamp: true,
                  rotation: this.rotationDataLabels
              },
              tooltip: {
                usePointStyle: true,
                mode: 'nearest',
                intersect: false,
                callbacks: {
                  title: function(context) {
                    const label = context[0].label
                    return label.replace(/,/g, '');
                  }
                }

              }
            },
            scales: {
              y: {
                ticks: {
                  padding: 10,
                  font: {
                    size: this.fontSize
                  },
                  maxTicksLimit: 8
                },
                grace: 1,

              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: this.fontSize
                  },
                  callback: function(value) {
                    return this.chart.data.labels[value]?.split(" ")[0];
                  },
                  minRotation: 0,
                  maxRotation: 90,
                },
              },
            },
          },
        }
      },
      computed: {
        chartDataName() {
            return this.dataApi
        },
        chartLabels() {
          return this.chartDataName.map(e => e[this.labelKey])
        },
        chartData() {
          return {
            labels: this.chartLabels,
            datasets: [
                {
                  backgroundColor: this.backgroundColor,
                  data: this.chartDataName.map(e => e[this.dataSetKey])
                }
            ],
          }
        }
      },
      methods: {
        firstWord(label) {
          const words = label.split(' ');
          return words[0]
        }
      },
      components: {
        Bar
      },
    }
  
  </script>