<template>
  <div class="relative">
    <div
      :class="`flex flex-col justify-center h-14 p-2 rounded-lg ${
        disabled
          ? 'bg-gray-lightest text-gray-darkest cursor-not-allowed'
          : 'bg-white'
      } border ${isOptionsExpanded ? 'border-secondary' : 'border-primary'}`"
    >
      <div class="relative text-sm w-full">
        <button
          class="flex items-center justify-between w-full text-lg rounded-md"
          :class="{
            'bg-gray-lightest text-gray-darkest cursor-not-allowed': disabled,
            ' ': !disabled && !noBorder,
            '': error,
            '': !noBorder && !disabled,
            'text-base': size != 'large',
            'h-12': (size = 'large'),
          }"
          @click="
            disabled
              ? (isOptionsExpanded = false)
              : (isOptionsExpanded = !isOptionsExpanded)
          "
        >
          <div
            class="absolute inset-y-0 left-0 pl-3 mr-6 flex items-center pointer-events-none"
            v-if="iconLeft"
          >
            <span :class="iconLeft"></span>
          </div>
          <div class="mr-6">
            <label
              class="flex items-center text-xs text-gray"
              :class="{ 'text-error': error, 'cursor-not-allowed': disabled }"
            >
              {{ label }}
            </label>
            <p
              class="text-base text-gray-dark"
              :class="{
                'pl-6': iconLeft,
                'cursor-not-allowed': disabled,
              }"
            >
              <slot name="selected" :slot-props="selected">{{
                selected || placeholder
              }}</slot>
            </p>
          </div>
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="!readOnly && isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
    </div>
    <transition
      enter-active-class="transform transition duration-500 ease-custom"
      enter-class="-translate-y-1/2 scale-y-0 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="transform transition duration-300 ease-custom"
      leave-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
    >
      <ul
        v-show="!readOnly && isOptionsExpanded"
        class="absolute left-0 right-0 mb-4 rounded-md overflow-hidden bg-white z-50"
        :class="{
          'shadow-primary-sm':
            !disabled && (color == undefined || color == 'primary'),
          'shadow-secondary-sm': !disabled && color == 'secondary',
          'shadow-tertiary-sm': !disabled && color == 'tertiary',
        }"
      >
        <li
          v-for="(v, i) in options"
          :key="i"
          class="px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white cursor-pointer"
          :label="label"
          :class="isActive ? 'bg-secondary text-white' : 'text-gray'"
          @click="setOption(v)"
        >
          <slot name="option" :slot-props="v">{{
            typeof v === "object" ? v.text : v
          }}</slot>
        </li>
      </ul>
    </transition>
    <div class="text-xs text-gray mt-1" v-if="hint && !dense">
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "large",
    },
    disabled: Boolean,
    noBorder: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    hint: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array,
  },
  name: "SelectDashboard",
  data() {
    return {
      isOptionsExpanded: false,
      selected: '',
    };
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      this.selected = typeof v === "object" ? v.text : v;
      this.$emit("change", v);
      this.$emit("input", this.value);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
