<template>
  <div class="flex flex-col justify-center items-center">
    <div class="relative w-10 h-10">
      <div class="absolute top-0 left-0 w-full h-full border-4 border-secondary-light rounded-full animate-spin" style="border-color: rgb(121, 126, 246) transparent transparent transparent;"></div>
    </div>
    <slot name="message"></slot>
  </div>
</template>
<script>
export default {
  name: "LoadingSpinner"
}
</script>