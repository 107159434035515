<template>
	<div v-if="menuReportChart?.canRead">
		<div class="sm:p-6 p-3.5 rounded-2xl shadow-gray-sm">
			<div class="flex flex-col w-full gap-6 mb-6 sm:items-center sm:flex-row">
				<h2 class="flex-auto text-3xl font-semibold text-gray-darkest">Dashboard</h2>
				<DownloadReport v-if="menuReportDownload?.canRead" class="flex-none w-full sm:w-auto" />
			</div>
			<CardInformation class="mb-6"/>
			<Top5Average/>
			<TotalRfx/>
			<div class="mb-6">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">SLA RFx</h3>
						<div class="flex-none">
							<SelectChart size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectSla" :options="dateListOption" @click="changeYearMonthlyChart($event, 'sla', 'selectSla')">
								<template #selected>
									{{ selectSla || 'Select' }}
								</template>
							</SelectChart>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.sla" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="sla.startYear" :endYear="sla.endYear" :dataApi="chartSla" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
			</div>
			<div  class="mb-6">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">SLA BAN</h3>
						<div class="flex-none">
							<SelectChart size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectBan" :options="dateListOption" @click="changeYearMonthlyChart($event, 'ban', 'selectBan')">
								<template #selected>
									{{ selectBan || 'Select' }}
								</template>
							</SelectChart>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.ban" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="ban.startYear" :endYear="ban.endYear" :dataApi="chartSlaBan" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
			</div>
			<div class="grid grid-cols-1 w-full gap-4">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">Efficiency Negotiation</h3>
						<div class="flex-none">
							<SelectChart size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectEfficiency" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiency', 'selectEfficiency')">
								<template #selected>
									{{ selectEfficiency || 'Select' }}
								</template>
							</SelectChart>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.efficiency" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="efficiency.startYear" :endYear="efficiency.endYear" :dataApi="chartEfficiency" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">Efficiency Negotiation in Billion</h3>
						<div class="flex-none">
							<SelectChart size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectEfficiencyMillion" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiencyMillion', 'selectEfficiencyMillion')">
								<template #selected>
									{{ selectEfficiencyMillion || 'Select' }}
								</template>
							</SelectChart>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.efficiencyMillion" class="p-28"/>
					<VueChart v-else keyName="value" :roundTooltip="true" :startYear="efficiencyMillion.startYear" :endYear="efficiencyMillion.endYear" :dataApi="chartEfficiencyMillion" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :datalabel="true" :fontSize="8" :rotationDataLabels="-45" :isBillion="true"></VueChart>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import SelectChart from '@/core/components/custom/SelectChart.vue';
import VueChart from '@/core/components/dashboard/VueChart.vue'
import TotalRfx from '@/core/components/dashboard/TotalRfx.vue'
import DownloadReport from '@/core/components/modal/DownloadReport.vue';
import CardInformation from '@/core/components/dashboard/CardInformation.vue';
import LineGraph from "@/core/components/dashboard/LineGraph.vue";
import LoadingSpinner from '@/core/components/custom/LoadingSpinner.vue';
import Top5Average from '@/core/components/dashboard/Top5Average.vue';

export default {
	name: "Dashboard",
	data() {
		return {
			sla: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			ban: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiency: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiencyMillion: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			cardLoading: {
				sla: true,
				ban: true,
				efficiency: true,
				efficiencyMillion: true,
			},
			selectSla: '',
			selectBan: '',
			selectEfficiency: '',
			selectEfficiencyMillion: '',
		}
	},
	computed: {
		chartSla() {
			return this.$store.state.report.chartSla
		},
		chartSlaBan() {
			return this.$store.state.report.chartSlaBan
		},
		chartEfficiency() {
			return this.$store.state.report.chartEfficiency
		},
		chartEfficiencyMillion() {
			return this.$store.state.report.chartEfficiencyMillion
		},
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
		dateListOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear-3, dateGetYear-2, dateGetYear-1, dateGetYear ]
		},
	},
	methods: {
		async changeYearMonthlyChart(e, key, select) {
			try {
				const startYear = e.startYear
				const endYear = e.endYear
				this[key].startYear = startYear
				this[key].endYear = endYear
				this[select] = `${e.startYear} - ${e.endYear}`
				this.cardLoading[key] = true
				await this.$store.dispatch('report/getDashboardMonthlyChart', { startYear: startYear, endYear: endYear, moduleName: key})
				this.cardLoading[key] = false
			} catch (e) {
				this.cardLoading[key] = false
			}
		},

	},
	async mounted() {
		this.changeYearMonthlyChart({ startYear: this.sla.startYear,  endYear: this.sla.endYear }, 'sla', 'selectSla')
		this.changeYearMonthlyChart({ startYear: this.ban.startYear,  endYear: this.ban.endYear }, 'ban', 'selectBan')
		this.changeYearMonthlyChart({ startYear: this.efficiency.startYear,  endYear: this.efficiency.endYear }, 'efficiency', 'selectEfficiency')
		this.changeYearMonthlyChart({ startYear: this.efficiencyMillion.startYear,  endYear: this.efficiencyMillion.endYear }, 'efficiencyMillion', 'selectEfficiencyMillion')
	},
	components: { 
		VueChart, 
		SelectChart,
		DownloadReport,
		CardInformation,
		LineGraph,
		LoadingSpinner,
		TotalRfx,
		Top5Average,
	}
}
</script>
