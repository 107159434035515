<template>
  <div class="relative">
    <div
      :class="`flex flex-col justify-center p-2 rounded-lg bg-white border border-primary hover:shadow-primary-sm`"
    >
      <div class="relative text-sm">
        <button
          class="flex items-center rounded-md"
          :class="{
            'text-base': size !== 'large',
            'h-12': (size === 'large'),
          }"
          @click="isOptionsExpanded = !isOptionsExpanded"
        >
          <div
            class="flex items-center pr-3 pointer-events-none"
          >
            <span :class="iconLeft"></span>
          </div>
          <div class="pr-3">
            <label
              class="flex items-center text-xs text-gray"
              :class="{ 'text-error': error }"
            >
              {{ label }}
            </label>
            <p
              class="text-gray-dark truncate"
              :class="{
                'text-lg': size === 'large',
                'text-sm': size === 'extra-small',
                'text-base': !size,
              }"
            >
              <slot name="selected" :slot-props="selected">{{
                selected || placeholder
              }}</slot>
            </p>
          </div>
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 transition-transform duration-200 ease-in-out transform"
            :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
    </div>
    <transition
      enter-active-class="transition duration-500 transform ease-custom"
      enter-class="scale-y-0 -translate-y-1/2 opacity-0"
      enter-to-class="scale-y-100 translate-y-0 opacity-100"
      leave-active-class="transition duration-300 transform ease-custom"
      leave-class="scale-y-100 translate-y-0 opacity-100"
      leave-to-class="scale-y-0 -translate-y-1/2 opacity-0"
    >
      <div v-show="isOptionsExpanded" 
        class="absolute left-0 right-0 z-30 px-3 overflow-hidden bg-white rounded-md shadow-primary-sm"
        >
        <div>
          <div v-for="(v,i) in options" :key="i">
            <GistaCheckbox class="text-gray" size="small" :class="{ 'text-lg': size === 'large', 'text-sm': size == 'extra-small', 'text-base': !size,}" @change="changeCheckBox($event, i)" :modelValue="modelValue[i]?.value">{{v}}</GistaCheckbox>
          </div>
        </div>
        <div class="w-full mb-3">
          <button-gista size="small" customClass="w-full" :disabled="disabledApply" @click="clickApply">Apply</button-gista>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "large",
    },
    error: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array,
    value: {
      type: [Object, String],
    },
    graph: Boolean
  },
  name: "SelectDashboard",
  data() {
    return {
      isOptionsExpanded: false,
      selected: typeof this.value === "object" ? this.value.text : this.value,
      modelValue: {},
      modelValueCurrent: [],
      disabledApply: true
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      this.computedValue = typeof v === "object" ? v.value : v;
      this.selected = typeof v === "object" ? v.text : v;
      this.$emit("change", v);
      this.$emit("input", this.value);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
    changeCheckBox(e, i) {
      this.setModelValueCurrent(i)
      let counter = 0
      for (const key in this.modelValue) {
        if (+key === this.modelValueCurrent[0] || +key === this.modelValueCurrent[1]) {
          if (+key === i) {
            this.modelValue[key] = {value: e}
          }
        } else {
          this.modelValue[key] = {value: false}
        }

        if (this.modelValue[key].value) counter++
      }
      if (counter < 2) {
        this.disabledApply = true
      } else {
        this.disabledApply = false
      }
    },
    setModelValueCurrent(i) {
      if (this.modelValueCurrent.length === 2) {
        if (!this.modelValueCurrent?.includes(i)) {
          this.modelValueCurrent.shift()
          this.modelValueCurrent.push(i)
        }
      } else {
        this.modelValueCurrent.push(i)
      }
    },
    clickApply() {
      this.isOptionsExpanded = false
      const sortModelValueCurrent = this.modelValueCurrent.sort()
      this.selected = `${this.options[sortModelValueCurrent[0]]} - ${this.options[sortModelValueCurrent[1]]}`
      this.$emit('click', { startYear: this.options[sortModelValueCurrent[0]], endYear: this.options[sortModelValueCurrent[1]] })
    }
    
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.modelValue = this.options.reduce((a,v,i) => ({ ...a, [i]: { value: false }}), {})
    const lengthOptions = this.options.length
    this.changeCheckBox(true, lengthOptions-2)
    this.changeCheckBox(true, lengthOptions-1)
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
