<template>
  <Bar 
    :chart-data="chartData" 
    :chart-options="chartOptions" 
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"/>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { parseLocaleNumber } from '@/core/components/helpers/formatRupiah'
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { Chart,
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle } from 'chart.js'
Chart.register(
  BarElement,
  PointElement,
  BarController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle);

  export default {
    name: 'VueChart',
    props: {
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 300
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => ({})
      },
      plugins: {
        type: Array,
        default: () => []
      },
      startYear: {
        type: Number,
        default: 2021
      },
      endYear: {
        type: Number,
        default: 2022
      },
      dataApi: {
        type: Array,
        default: () => []
      },
      backgroundColor: {
        type: String,
        default: '#30D5C9'
      },
      backgroundColor2: {
        type: String,
        default: '#797EF6'
      },
      datalabel: {
        type: Boolean,
        default: false,
      },
      roundTooltip: {
        type: Boolean,
        default: false
      },
      keyName: {
        type: String,
        default: 'total'
      },
      isBillion: {
        type: Boolean,
        default: false
      },
      rotationDataLabels: {
        type: Number,
        default: 0,
      },
      fontSize: {
        type: Number,
        default: 10
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          plugins: {
            legend: {
              align: 'end',
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              display: this.datalabel,
              formatter: (value) => {
                return this.datalabelsFormatter(value)
              },
              anchor: function(context) {
                const value = context.dataset.data[context.dataIndex]
                return value >= 0 ? 'end' : 'start'
              },
              font: {
                size: this.fontSize
              },
              align: function(context) {
                const value = context.dataset.data[context.dataIndex]
                return value >= 0 ? 'end' : 'start'
              },
              offset: 1,
              clamp: true,
              rotation: this.rotationDataLabels
            },
            ...this.roundTooltip ? 
            {tooltip: {
              usePointStyle: true,
              mode: 'nearest',
              intersect: false,
              callbacks: {
                label: (tooltipItem) => {
                  const value = tooltipItem.raw;
                  return formatCurrency(value, 'idr', null, 0, 3)
              }
              },
            }}: {}
          },
          scales: {
            y: {
              ticks: {
                padding: 10,
                font: {
                  size: this.fontSize
                },
                maxTicksLimit: 8,
                callback: (value) => {
                  return formatCurrency(value, 'idr', null, 0, 3)
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: this.fontSize
                },
              },
              offset: true,
              minRotation: 0,
              maxRotation: 90
            },
          },
        },
      }
    },
    computed: {
      chartStartYear() {
        return this.dataApi?.filter(e => e.year === +this.startYear)
      },
      chartEndYear() {
        return this.dataApi?.filter(e => e.year === +this.endYear)
      },
      chartLabels() {
        return this.chartStartYear.map(e => e.monthMMM_ENG, 10)
      },
      chartData() {
        const name = this.keyName
        return {
          labels: this.chartLabels,
          datasets: [
            {
              label: this.startYear,
              backgroundColor: this.backgroundColor,
              data: this.chartStartYear.map(e => e[name])
            },
            {
              label: this.endYear,
              backgroundColor: this.backgroundColor2,
              data: this.chartEndYear.map(e => e[name])
            }
          ],
        }
      }
    },
    methods: {
      datalabelsFormatter(value) {
        const roundingMode = value > 0 ? 'floor' : 'ceil'
        const parseNumber = parseLocaleNumber(value, 'usd')
        if (this.isBillion && Math.abs(value) >= 1000000000) {
          return formatCurrency(parseNumber/1000000000, 'idr', null, 0, 0, roundingMode) + 'B'
        } else if (this.isBillion && Math.abs(value) >= 1000000) {
          return formatCurrency(parseNumber/1000000000, 'idr', null, 0, 3, roundingMode) + 'B'
        } else if (this.isBillion && Math.abs(value) > 0) {
          return formatCurrency(parseNumber/1000000000, 'idr', null, 0, 9, roundingMode) + 'B'
        } else if (this.isBillion) {
          return formatCurrency(parseNumber/1000000000, 'idr', null, 0, 0, roundingMode) + 'B'
        } else {
          return value
        }
      },
    },
    components: {
      Bar
    }
  }

</script>