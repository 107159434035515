export const formatDate = (value) => {
  let currentDate = new Date(value)
  let date = currentDate.getDate().toString()
  let month = (currentDate.getMonth() + 1).toString()
  let year = currentDate.getFullYear().toString()
  return `${date.padStart(2, 0)}/${month.padStart(2, 0)}/${year}`
}

export const formatDate2 = (value) => {
  let currentDate = new Date(value)
  let date = currentDate.getDate().toString()
  let month = (currentDate.getMonth() + 1).toString()
  let year = currentDate.getFullYear().toString()
  return `${date.padStart(2, 0)}-${month.padStart(2, 0)}-${year}`
}

export const formatDate3 = (value) => {
  if (!value) {
    return ''
  }
  const dateSplit = value.split('/')
  const valDay = dateSplit[0]
  const valMonth = dateSplit[1]
  const valYear = dateSplit[2]
  const currentDate = new Date(valYear, valMonth - 1, valDay)
  const date = currentDate.getDate().toString()
  const month = currentDate.toLocaleString('id-ID', { month: 'long'} )
  const year = currentDate.getFullYear().toString()
  return `${date.padStart(2, 0)} ${month} ${year}`
}

export const formatDate4 = (value) => {
  if (!value) {
    return ''
  }
  const currentDate = new Date(value)
  const date = currentDate.getDate().toString()
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(currentDate)
  const year = currentDate.getFullYear().toString()
  const hour = ("0" + currentDate.getHours()).slice(-2)
  const minutes = ("0" + currentDate.getMinutes()).slice(-2)
  return `${date.padStart(2,0)} ${month} ${year} ${hour}:${minutes}`
}