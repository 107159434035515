<template>
	<div class="flex justify-center">
		<div class="w-full">
			<CDD v-if="menuOfficialSignature?.canRead"/>
		</div>
	</div>
</template>

<script>
import CDD from '@/modules/module-config/pages/CDD.vue'

export default {
	name: "Config",
	computed: {
    menuOfficialSignature() {
			return this.$store.getters['dashboard/menuOfficialSignature']
		},
	},
	methods: {
		changeActiveMenu(val) {
			this.activeMenu = val
		},
	},
	components: {
		CDD
	}
};
</script>
