<template>
	<div v-if="menuReportChart?.canRead">
		<div class="sm:p-6 p-3.5 rounded-2xl shadow-gray-sm">
			<div class="flex flex-col w-full gap-6 mb-6 sm:items-center sm:flex-row">
				<h2 class="flex-auto text-3xl font-semibold text-gray-darkest">Dashboard</h2>
				<DownloadReport v-if="menuReportDownload?.canRead" class="flex-none w-full sm:w-auto" />
			</div>
			<CardInformation class="mb-6" :loading="cardLoading.information" />
			<div class="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-6">
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-2.5 mb-6 flex-end">
						<h3 class="flex-auto text-sm break-all">Top 5 Average RFx</h3>
						<SelectGista 
							placeholder="Select"
							size="small"
							:options="dateListAvgOption"
							:value="`${avg.rfx}`"
							:error="false"
							iconLeft="icon-filter text-gray"
							@change="(e) => changeYearAvg(e, 'top5Rfx', 'rfx')"
							class="flex-none w-28" >
							<template #selected>
								{{ `${avg.rfx}` || 'Select' }}
							</template>
						</SelectGista>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Rfx" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgRfx"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm break-all">Top 5 Average Approval</h3>
						<SelectGista 
							placeholder="Select"
							size="small"
							:options="dateListAvgOption"
							:value="`${avg.approval}`"
							:error="false"
							iconLeft="icon-filter text-gray"
							@change="(e) => changeYearAvg(e, 'top5Approval', 'approval')"
							class="flex-none w-28" >
							<template #selected>
								{{ `${avg.approval}` || 'Select' }}
							</template>
						</SelectGista>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Approval" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgApproval"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm break-all">Top 5 Average Process</h3>
						<SelectGista 
							placeholder="Select"
							size="small"
							:options="dateListAvgOption"
							:value="`${avg.process}`"
							:error="false"
							iconLeft="icon-filter text-gray"
							@change="(e) => changeYearAvg(e, 'top5Doc', 'process')"
							class="flex-none w-28" >
							<template #selected>
								{{ `${avg.process}` || 'Select' }}
							</template>
						</SelectGista>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Doc" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgDoc"></AverageChart>
				</div>
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-6">
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">RFX</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectRfx" :options="dateListOption" @click="changeYear($event, 'rfx', 'selectRfx')">
							<template #selected>
								{{ selectRfx || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.rfx" class="p-28"/>
					<VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfx.startYear" :endYear="rfx.endYear" :dataApi="chartDataRFX"></VueChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">RFP</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :value="selectRfp" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfp', 'selectRfp')">
							<template #selected>
								{{ selectRfp || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.rfp" class="p-28"/>
					<VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfp.startYear" :endYear="rfp.endYear" :dataApi="chartDataRFP"></VueChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">RFQ</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :value="selectRfq" :graph="true" :options="dateListOption" @click="changeYear($event, 'rfq', 'selectRfq')">
							<template #selected>
								{{ selectRfq || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.rfq" class="p-28"/>
					<VueChart v-else :datalabel="true" :roundTooltip="true" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :startYear="rfq.startYear" :endYear="rfq.endYear" :dataApi="chartDataRFQ"></VueChart>
				</div>
			</div>
			<div class="mb-6">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">SLA RFx</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectSla" :options="dateListOption" @click="changeYearMonthlyChart($event, 'sla', 'selectSla')">
							<template #selected>
								{{ selectSla || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.sla" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="sla.startYear" :endYear="sla.endYear" :dataApi="chartSla" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
			</div>
			<div  class="mb-6">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">SLA BAN</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectBan" :options="dateListOption" @click="changeYearMonthlyChart($event, 'ban', 'selectBan')">
							<template #selected>
								{{ selectBan || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.ban" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="ban.startYear" :endYear="ban.endYear" :dataApi="chartSlaBan" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
			</div>
			<div class="grid grid-cols-1 w-full gap-4">
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">Efficiency Negotiation</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectEfficiency" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiency', 'selectEfficiency')">
							<template #selected>
								{{ selectEfficiency || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.efficiency" class="p-28"/>
					<LineGraph v-else :datalabel="true" :startYear="efficiency.startYear" :endYear="efficiency.endYear" :dataApi="chartEfficiency" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :roundTooltip="true" :sla="true"/>
				</div>
				<div class="w-full sm:p-6 p-3.5 border rounded-lg border-gray-lightest shadow-gray-md">
					<div class="flex flex-wrap gap-3.5 mb-6">
						<h3 class="flex-auto text-sm lg:text-base">Efficiency Negotiation in Million</h3>
						<SelectChart class="flex-none" size="small" iconLeft="icon-filter text-gray" :graph="true" :value="selectEfficiencyMillion" :options="dateListOption" @click="changeYearMonthlyChart($event, 'efficiencyMillion', 'selectEfficiencyMillion')">
							<template #selected>
								{{ selectEfficiencyMillion || 'Select' }}
							</template>
						</SelectChart>
					</div>
					<LoadingSpinner v-if="cardLoading.efficiencyMillion" class="p-28"/>
					<VueChart v-else keyName="value" :roundTooltip="true" :startYear="efficiencyMillion.startYear" :endYear="efficiencyMillion.endYear" :dataApi="chartEfficiencyMillion" backgroundColor="#ACB7FF" backgroundColor2="#85ECE4" :datalabel="true" :inMillion="true" ></VueChart>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import SelectChart from '@/core/components/custom/SelectChart.vue';
import VueChart from '@/core/components/dashboard/VueChart.vue'
import DownloadReport from '@/core/components/modal/DownloadReport.vue';
import CardInformation from '@/core/components/dashboard/CardInformation.vue';
import LineGraph from "@/core/components/dashboard/LineGraph.vue";
import AverageChart from '@/core/components/dashboard/AverageChart.vue';
import LoadingSpinner from '@/core/components/custom/LoadingSpinner.vue';

export default {
	name: "Dashboard",
	data() {
		return {
			rfx: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfp: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			rfq: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			rfi: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear()
			},
			avg: {
				rfx: new Date().getFullYear(),
				approval: new Date().getFullYear(),
				process: new Date().getFullYear()
			},
			sla: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			ban: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiency: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			efficiencyMillion: {
				startYear: new Date().getFullYear() - 1,
				endYear: new Date().getFullYear(),
			},
			cardLoading: {
				information: true,
				top5Rfx: true,
				top5Approval: true,
				top5Doc: true,
				rfx: true,
				rfp: true,
				rfq: true,
				sla: true,
				ban: true,
				efficiency: true,
				efficiencyMillion: true,
			},
			selectRfx: '',
			selectRfp: '',
			selectRfq: '',
			selectSla: '',
			selectBan: '',
			selectEfficiency: '',
			selectEfficiencyMillion: '',
		}
	},
	computed: {
		chartDataRFX() {
			return this.$store.state.report.chartDataRFX
		},
		chartDataRFP() {
			return this.$store.state.report.chartDataRFP
		},
		chartDataRFQ() {
			return this.$store.state.report.chartDataRFQ
		},
		chartDataRFI() {
			return this.$store.state.report.chartDataRFI
		},
		chartAvgRfx() {
			return this.$store.state.report.chartAvgRfx
		},
		chartAvgApproval() {
			return this.$store.state.report.chartAvgApproval
		},
		chartAvgDoc() {
			return this.$store.state.report.chartAvgDoc
		},
		chartSla() {
			return this.$store.state.report.chartSla
		},
		chartSlaBan() {
			return this.$store.state.report.chartSlaBan
		},
		chartEfficiency() {
			return this.$store.state.report.chartEfficiency
		},
		chartEfficiencyMillion() {
			return this.$store.state.report.chartEfficiencyMillion
		},
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
		dateListOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear-3, dateGetYear-2, dateGetYear-1, dateGetYear ]
		},
		dateListAvgOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear, dateGetYear-1 ]
		},
	},
	methods: {
		async changeYear(e, key, select) {
			const startYear = e.startYear
			const endYear = e.endYear
			this[key].startYear = startYear
			this[key].endYear = endYear
			this[select] = `${e.startYear} - ${e.endYear}`
			this.cardLoading[key] = true
			await this.$store.dispatch('report/getReportChart', { startYear: startYear, endYear: endYear, moduleName: key})
			this.cardLoading[key] = false
		},
		async changeYearAvg(e, key, avgKey) {
			this.avg[avgKey] = e
			this.cardLoading[key] = true
			await this.$store.dispatch('report/getReportChart', { startYear: e, endYear: e, moduleName: key})
			this.cardLoading[key] = false
		},
		async changeYearMonthlyChart(e, key, select) {
			try {
				const startYear = e.startYear
				const endYear = e.endYear
				this[key].startYear = startYear
				this[key].endYear = endYear
				this[select] = `${e.startYear} - ${e.endYear}`
				this.cardLoading[key] = true
				await this.$store.dispatch('report/getDashboardMonthlyChart', { startYear: startYear, endYear: endYear, moduleName: key})
				this.cardLoading[key] = false
			} catch (e) {
				this.cardLoading[key] = false
			}
		},

	},
	async mounted() {
		this.changeYear({ startYear: this.rfx.startYear,  endYear: this.rfx.endYear }, 'rfx', 'selectRfx')
		this.changeYear({ startYear: this.rfp.startYear,  endYear: this.rfp.endYear }, 'rfp', 'selectRfp')
		this.changeYear({ startYear: this.rfq.startYear,  endYear: this.rfq.endYear }, 'rfq', 'selectRfq')
		this.changeYearMonthlyChart({ startYear: this.sla.startYear,  endYear: this.sla.endYear }, 'sla', 'selectSla')
		this.changeYearMonthlyChart({ startYear: this.ban.startYear,  endYear: this.ban.endYear }, 'ban', 'selectBan')
		this.changeYearMonthlyChart({ startYear: this.efficiency.startYear,  endYear: this.efficiency.endYear }, 'efficiency', 'selectEfficiency')
		this.changeYearMonthlyChart({ startYear: this.efficiencyMillion.startYear,  endYear: this.efficiencyMillion.endYear }, 'efficiencyMillion', 'selectEfficiencyMillion')
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Rfx', 'rfx')
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Approval', 'approval')
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Doc',	'procs')
		this.cardLoading.information = true
		await this.$store.dispatch('report/getDashboardTop')
		this.cardLoading.information = false

		
	},
	components: { 
		VueChart, 
		SelectChart,
		DownloadReport,
		CardInformation,
		LineGraph,
		AverageChart,
		LoadingSpinner
	}
}
</script>
