<template>
  <Card class="w-full mb-6" padding="p-3.5 sm:p-6">
    <div>
      <div class="flex flex-wrap items-center gap-6 mb-6">
        <h4 class="flex flex-auto text-3xl font-semibold">List Approver di Sistem</h4>
        <div class="flex flex-col w-full gap-6 sm:flex-none sm:flex-row sm:w-auto">
          <InputGista type=text
            placeholder="Search"
            v-model="search"
            >
            <template #icon-left>
              <span class="text-xl icon-search text-primary"></span>
            </template>
          </InputGista>
          <AddOfficial />
        </div>
      </div>
      <TableGista :items="listApprover?.content"
				:headers="headers"
				:sort-order="sortParams"
				:options.sync="queryParams"
				:show-pagination="true"
				:searchable="false"
				:server-side="true"
				:row-class="(data,i) => !data?.isActive? 'bg-gray-lightest' : i % 2 === 0? 'bg-primary-lightest bg-opacity-25' : 'bg-white'"
				class="mt-6"
				tdClass="align-top"
				@update:options="updatePagination"
				@refresh="fetchData">
				<template v-slot:no="{ slotProps }">
					{{ slotProps.index + 1 }}
				</template>
				<template v-slot:isActive="{ slotProps }">
					<div class="flex items-center pl-2 pr-8">
						<div v-if="slotProps?.data?.isActive" class="flex items-center px-4 py-2 rounded-lg bg-primary">
							<span class="text-xs font-semibold leading-normal text-white">Active</span>
						</div>
						<div v-else class="flex items-center px-4 py-2 rounded-lg bg-gray-light">
							<span class="text-xs font-semibold leading-normal text-white">Inactive</span>
						</div>
					</div>
				</template>
				<template v-slot:action="{ slotProps }">
					<div class="flex items-center pl-2">
						<DeleteOfficial @refetch="fetchData" :employeeData="slotProps?.data" v-if="slotProps?.data?.isActive"/>
						<RestoreOfficial @refetch="fetchData" :employeeData="slotProps?.data" v-else />
					</div>
				</template>
			</TableGista>
    </div>
	</Card>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import AddOfficial from '@/modules/module-config/components/AddOfficial.vue';
import DeleteOfficial from '@/modules/module-config/components/DeleteOfficial.vue';
import RestoreOfficial from '@/modules/module-config/components/RestoreOfficial.vue';
export default {
  name: 'CDD',
  data () {
    return {
      search: '',
      isLoadingTable: true,
      init: true,
      timeout: null,
      headers: [
				{
					value: "no",
					text: "No",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "name",
					text: "Nama Pejabat",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "jobTitle",
					text: "Job Title",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "domain",
					text: "Udomain",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "isActive",
					text: "Status",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "action",
					text: "Action",
					clickable: false,
					sortable: false,
					align: "start",
				},

			],
			sortParams: [
			],
			queryParams: {
				totalItems: 0,
				itemsPerPage: 10,
				page: 0
			},
    }
  },
  computed: {
    menuOfficialSignature() {
			return this.$store.getters['dashboard/menuOfficialSignature']
		},
    listApprover() {
			return this.$store.state.config.listApprover
		},
  },
  watch: {
    search() {
      this.searchFetch()
    },
		listApprover() {
			this.queryParams = {
				totalItems: this.listApprover?.totalElements,
				itemsPerPage: this.listApprover?.size,
				page: this.listApprover?.number + 1
			}
		}
  },
  methods: {
    searchFetch() {
      if (this.timeout) {
        clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
        this.fetchData()
      }, 500)
    },
    changeIsLoadingTable(e) {
			this.isLoadingTable = e
		},
    updatePagination(e) {
			this.queryParams = {
				page: e.page,
				itemsPerPage: e.itemsPerPage,
				totalItems: e.totalItems,
			}
		},
		async fetchData() {
			this.isLoadingTable = true
			await this.$store.dispatch('config/getApproverList', { page: this.queryParams.page, size: this.queryParams.itemsPerPage, search: this.search })
			this.isLoadingTable = false
		},
  },
	async mounted() {
		this.fetchData()
	},
  components: {
    Card,
    AddOfficial,
    DeleteOfficial,
    RestoreOfficial
  }
}
</script>