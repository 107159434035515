<template>
  <div>
    <date-picker
      :mode="mode"
      :is24hr="is24hr"
      :is-range="isRange"
      @input="handleSelected"
      v-model="computedValue"
      :model-config="modelConfig"
      :min-date="minDate"
      :max-date="maxDate"
      :popover="{ visibility: disabled? 'hidden': 'hover-focus'}"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex flex-col">
          <div v-if="label != ''">
            <label
              class="block text-sm text-gray md:text-left mb-1 md:mb-0 pr-4"
              :class="[{ 'text-error': error }]"
            >
              {{ label }}
            </label>
          </div>
          <div
            class="relative"
            :class="[
              {
                'text-small': size != 'large',
              },
            ]"
          >
            <div
              class="h-14 p-2 border text-gray rounded-lg border-primary"
              :value="inputValue.start"
              v-on="inputEvents.start"
            >
              <div class="flex justify-between items-center w-full">
                <div class="flex flex-col justify-between mr-3">
                  <p class="text-xs text-gray">Data created:</p>
                  <p class="text-gray-dark">{{showDate(computedValue)}}</p>
                </div>
                <span class="icon-calendar text-lg"></span>
              </div>

            </div>
            <div
              class="absolute icon-calendar inset-y-0 flex items-center right-0 pr-4"
              :class="{ 'text-gray': !error, 'text-error': error }"
              style="font-weight: 600"
              v-if="withIcon != undefined"
            ></div>
          </div>
        </div>
      </template>
    </date-picker>
    <slot></slot>
  </div>
</template>

<script>
import DatePicker from "v-calendar/src/components/DatePicker";
import { formatDate } from '@/core/components/helpers/format.js'
export default {
  name: "DatepickerCustom",
  props: {
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    is24hr: {
      type: String,
    },
    mode: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    "with-icon": {
      type: String,
    },
    error: {
      type: Boolean,
    },
    "is-range": {
      type: Boolean,
      default: true,
    },
    updateSelect: {
      type: String,
    },
    size: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "DD-MM-YYYY", // Uses 'iso' if missing
      },
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    formatDate,
    handleSelected(value) {
      this.$emit("update:input", value);
    },
    changeFormatDate(value) {
      if(value instanceof Date) return value
      const date = value.split('-');
      return `${date[1]}/${date[0]}/${date[2]}`
    },
    showDate(value) {
      const {start, end} = value
      if(!start || !end) return this.placeholder
      const startDate = this.changeFormatDate(start)
      const endDate = this.changeFormatDate(end)
      return `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`
    },
  },
};
</script>
