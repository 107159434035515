<template>
  <div>
    <div class="grid w-full gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-5">
      <div class="border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-sm font-semibold text-gray">Total <span class="break-all">Accumulation</span> RFP</h5>
            <img src="@/core/assets/icons/Dashboard/Buat PO.svg" class="flex-none w-6" alt="Buat PO" />
          </div>
          <div>
            <LoadingSpinner v-if="cardLoading.information" class="p-4"/>
            <h5 v-else class="text-3xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.totalAccumulationRfp}}</h5>
          </div>
        </div>
      </div>
      <div class="border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-sm font-semibold text-gray">Total <span class="break-all">Accumulation</span> RFQ</h5>
            <img src="@/core/assets/icons/Dashboard/List PO.svg" class="flex-none w-6" alt="List PO" />
          </div>
          <div>
		        <LoadingSpinner v-if="cardLoading.information" class="p-4"/>
            <h5 v-else class="text-3xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.totalAccumulationRfq}}</h5>
          </div>
        </div>
      </div>
      <div class="border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-sm font-semibold text-gray">SLA RFX (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-6" alt="Dashboard" />
          </div>
          <div>
            <LoadingSpinner v-if="cardLoading.information" class="p-4"/>
            <h5 v-else class="text-3xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.slaRfxPercentage}}%</h5>
          </div>
        </div>
      </div>
      <div class="border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-sm font-semibold text-gray">SLA BAN (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-6" alt="Dashboard" />
          </div>
          <div>
            <LoadingSpinner v-if="cardLoading.information" class="p-4"/>
            <h5 v-else class="text-3xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.slaBanPercentage}}%</h5>
          </div>
        </div>
      </div>
      <div class="border rounded-lg border-gray-lightest shadow-gray-sm">
        <div class="p-4">
          <div class="flex gap-2.5 mb-4">
            <h5 class="flex-auto text-sm font-semibold text-gray">Efficiency (This Month)</h5>
            <img src="@/core/assets/icons/Dashboard/Dashboard.svg" class="flex-none w-6" alt="Dashboard" />
          </div>
          <div>
            <LoadingSpinner v-if="cardLoading.information" class="p-4"/>
            <h5 v-else class="text-3xl font-semibold leading-normal text-secondary-darkest">{{cardInformation.efficiency}}%</h5>
          </div>
          <div>
            <h5 v-if="!cardLoading.information" class="text-gray break-all">Rp {{cardInformation.efficiencyNegotiation}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/core/components/custom/LoadingSpinner.vue';

export default {
  name: "CardInformation",
  data() {
    return {
      cardLoading: {
        information: true,
      }
    }
  },
  computed: {
    cardInformation() {
      return this.$store.state.report.cardInformation
    }
  },
  async mounted() {
    this.cardLoading.information = true
		await this.$store.dispatch('report/getDashboardTop')
		this.cardLoading.information = false
  },
  components: {
    LoadingSpinner
  }
}
</script>