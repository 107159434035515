<template>
  <div class="flex flex-nowrap w-full">
    <template v-for="n,i in options" >
      <button v-if="n" :key="i" :title="n" 
        class="flex items-center justify-center truncate px-6 py-2 font-semibold border" 
        :class="`w-1/${options.length} ${disabled[i] ? 'border-tertiary-lightest' : 'border-tertiary'} ${selected === n? 'bg-tertiary text-white': disabled[i] ? 'bg-white text-secondary text-gray-lightest' : 'bg-white text-tertiary hover:text-tertiary-dark'} ${i === 0? 'border-r-0 rounded-l-lg' : i === options.length-1? 'border-l-0 rounded-r-lg': ''}`" 
        @click="click(i)" :disabled="disabled[i]">
        <p>{{n}}</p> 
        <p v-if="isPoint && pointRed[i]" class="ml-2.5 w-2.5 h-2.5 rounded-full bg-error"></p>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: "ThreeButton",
  props: {
    options: Array,
    value: String,
    isPoint: {
      type: Boolean,
      default: false,
    },
    pointRed: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    selected() {
      return this.value
    }
  },
  methods: {
    click(i) {
      this.$emit('click', this.options[i])
    }
  }
}
</script>