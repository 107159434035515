<template>
  <div class="flex">
    <div class="w-full">
      <div class="animate-pulse">
        <div v-for="n in 10" :key="n" class="h-48 bg-gray-lightest rounded-2xl w-full mb-6"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectLoading"
}
</script>