<template>
	<div>
		<div class="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-6">
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex gap-2.5 mb-6 flex-end">
						<div class="flex-auto">
							<h3 class="text-sm break-words">Top 5 Average RFx</h3>
							<h5 class="text-xs text-gray break-words">Days (Lower is Better) </h5>
						</div>
						<div>
							<SelectGista 
								placeholder="Select"
								size="small"
								:options="dateListAvgOption"
								:value="`${avg.rfx}`"
								:error="false"
								iconLeft="icon-filter text-gray"
								@change="(e) => changeYearAvg(e, 'top5Rfx', 'rfx')"
								class="flex-none w-28" >
								<template #selected>
									{{ `${avg.rfx}` || 'Select' }}
								</template>
							</SelectGista>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Rfx" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgRfx" :backgroundColor="avg.rfx === dateListAvgOption[0]? '#85ECE4' : '#ACB7FF'" dataSetKey="average" :fontSize="8"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex gap-3.5 mb-6">
						<div class="flex-auto">
							<h3 class="flex-auto text-sm break-words">Top 5 Average Approval</h3>
							<h5 class="text-xs text-gray break-words">Days (Lower is Better) </h5>
						</div>
						<div>
							<SelectGista 
								placeholder="Select"
								size="small"
								:options="dateListAvgOption"
								:value="`${avg.approval}`"
								:error="false"
								iconLeft="icon-filter text-gray"
								@change="(e) => changeYearAvg(e, 'top5Approval', 'approval')"
								class="flex-none w-28" >
								<template #selected>
									{{ `${avg.approval}` || 'Select' }}
								</template>
							</SelectGista>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Approval" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgApproval" :backgroundColor="avg.approval === dateListAvgOption[0]? '#85ECE4' : '#ACB7FF'" dataSetKey="average" :fontSize="8"></AverageChart>
				</div>
				<div class="w-full p-3 rounded-lg shadow-gray-sm">
					<div class="flex gap-3.5 mb-6">
						<div class="flex-auto">
							<h3 class="flex-auto text-sm break-words">Top 5 Average Process</h3>
							<h5 class="text-xs text-gray break-words">Days (Lower is Better) </h5>
						</div>
						<div>
							<SelectGista 
								placeholder="Select"
								size="small"
								:options="dateListAvgOption"
								:value="`${avg.process}`"
								:error="false"
								iconLeft="icon-filter text-gray"
								@change="(e) => changeYearAvg(e, 'top5Doc', 'process')"
								class="flex-none w-28" >
								<template #selected>
									{{ `${avg.process}` || 'Select' }}
								</template>
							</SelectGista>
						</div>
					</div>
					<LoadingSpinner v-if="cardLoading.top5Doc" class="p-28"/>
					<AverageChart v-else :datalabel="true" :dataApi="chartAvgDoc" :backgroundColor="avg.process === dateListAvgOption[0]? '#85ECE4' : '#ACB7FF'" dataSetKey="average" :fontSize="8"></AverageChart>
				</div>
			</div>
	</div>
</template>

<script>
import AverageChart from '@/core/components/dashboard/AverageChart.vue';
import LoadingSpinner from '@/core/components/custom/LoadingSpinner.vue';

export default {
	name: "Dashboard",
	data() {
		return {
			avg: {
				rfx: new Date().getFullYear(),
				approval: new Date().getFullYear(),
				process: new Date().getFullYear()
			},
			cardLoading: {
				top5Rfx: true,
				top5Approval: true,
				top5Doc: true,
			},
		}
	},
	computed: {
		chartAvgRfx() {
			return this.$store.state.report.chartAvgRfx
		},
		chartAvgApproval() {
			return this.$store.state.report.chartAvgApproval
		},
		chartAvgDoc() {
			return this.$store.state.report.chartAvgDoc
		},
		dateListAvgOption() {
			const date = new Date()
			const dateGetYear = date.getFullYear()
			return [ dateGetYear, dateGetYear-1 ]
		},
	},
	methods: {
		async changeYearAvg(e, key, avgKey) {
			this.avg[avgKey] = e
			this.cardLoading[key] = true
			await this.$store.dispatch('report/getReportChart', { startYear: e, endYear: e, moduleName: key})
			this.cardLoading[key] = false
		},
	},
	async mounted() {
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Rfx', 'rfx')
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Approval', 'approval')
		this.changeYearAvg(this.dateListAvgOption[0], 'top5Doc',	'procs')
	},
	components: { 
		AverageChart,
		LoadingSpinner,
	}
}
</script>
