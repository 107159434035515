<template>
	<Card v-if="menuReportChart?.canRead" class="w-full mb-6" padding="p-3.5 sm:p-6">
		<div class="flex gap-3.5 flex-col justify-between w-full mb-6 sm:flex-row">
			<h4 class="text-3xl font-semibold">Report</h4>
			<DownloadReport  v-if="menuReportDownload?.canRead" />
		</div>
		<CardInformation/>
	</Card>
</template>

<script>
import CardInformation from '@/core/components/dashboard/CardInformation.vue'
import DownloadReport from '@/core/components/modal/DownloadReport'
import Card from '@/core/components/custom/Card.vue'

export default {
	name: "Reports",
	computed: {
		menuReportChart() {
			return this.$store.getters['dashboard/menuReportChart']
		},
		menuReportDownload() {
			return this.$store.getters['dashboard/menuReportDownload']
		},
	},
	components: { 
		DownloadReport, 
		Card, 
		CardInformation
	}
}
</script>