<template>
	<div>
		<ButtonGista type="primary" color="error" size="small" :disabled="!employeeData?.enableAction" @click="clickBtn" customClass="w-9 text-lg">
			<span class="icon-trash-2"></span>
		</ButtonGista>
		<gista-modal :value="isOpen" customClass="max-w-2xl bg-white" :scrollable="true" @input="closeModal">
			<div class="w-full p-6">
				<div class="flex justify-center mb-6">
					<img src="@/core/assets/icons/Dashboard/ID.svg" class="w-24" alt="ID" />
				</div>
				<div class="flex justify-center mb-6">
					<h1 class="text-xl font-semibold leading-normal text-primary">Delete User?</h1>
				</div>
				<div class="flex justify-center mb-6">
					<p class="leading-normal text-center text-gray">Apakah Anda yakin ingin menghapus User?</p>
				</div>
				<div class="flex gap-2.5 justify-center">
					<button-gista type="secondary" color="error" @click="clickCancel">Cancel</button-gista>
					<button-gista type="primary" @click="click">Delete</button-gista>
				</div>
			</div>
		</gista-modal>
	</div>
</template>

<script>
import { MESSAGE_SUCCESS_DELETE_OFFICIAL } from '@/core/constant/successMessage'

export default {
	name: "DeleteOfficial",
	props: ['employeeData'],
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
	},
	methods: {
		async click() {
			this.modal.modalLoading = true
			const formData = new FormData()
			formData.append('isActive', false)
			formData.append('name', this.employeeData?.name)
			formData.append('domain', this.employeeData?.domain)
			formData.append('jobTitle', this.employeeData?.jobTitle)
			const response = await this.$store.dispatch('config/updateStatusOfficialSignature', {id: this.employeeData.officialSignatureId, formData})
			if (response?.status < 300 && response?.status >= 200) {
				this.selected = ''
				this.modal.modalLoading = false
				this.isOpen = false
				this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_DELETE_OFFICIAL }
				this.$emit('refetch')
			} else {
				this.$store.dispatch('modal/throwError', { response })
			}
		},
		clickCancel() {
			this.closeModal()
		},
		closeModal() {
			this.isOpen = false
		},
		clickBtn() {
			if(this.employeeData.enableAction) this.isOpen = true
		}
	}
}
</script>