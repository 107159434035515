<template>
	<div>
		<div class="flex flex-wrap w-full gap-3.5 mb-6 md:justify-between">
			<div class="flex flex-wrap gap-3.5 w-full lg:w-max">
				<select-dashboard class="flex-auto w-full sm:w-28 lg:flex-none"
					label="Type:"
					:placeholder="filterProject.selectedType"
					@change="changeTypeWithResetPagination"
					:options="['All', 'RFP', 'RFQ']">
				</select-dashboard>
				<date-picker-custom class="flex-auto w-full sm:w-72 lg:flex-none"
					@input="updateDateWithResetPagination($event)"
					mode="date"
					:max-date="maxDate"
					placeholder="Select Date"
					:value="filterProject.formDate"
					size="large"
					is-range>
				</date-picker-custom>
			</div>
			<div class="flex flex-wrap w-full sm:flex-nowrap lg:w-max sm:grid sm:gap-3 gap-3.5 sm:grid-cols-2">
				<select-dashboard class="flex-auto w-full"
					label="Sort:"
					:placeholder="filterProject.selectedSort"
					@change="changeSortWithResetPagination"
					:options="['Date Created: Newest', 'Date Created: Oldest']">
				</select-dashboard>
				<InputGista type=text
					size="large"
					placeholder="Search"
					v-model="filterProject.search"
					@input="searchWithResetPagination"
					class="flex-auto w-full">
					<template #icon-left>
						<span class="text-xl icon-search text-primary"></span>
					</template>
				</InputGista>
			</div>
		</div>
		<div class="flex flex-col">
			<div v-if="projectLogStatusDraft?.content?.length > 0 && !isLoading">
				<Card v-for="(project, i) in projectLogStatusDraft?.content"
					:key="i"
					class="flex flex-col items-center gap-3.5 justify-between w-full mb-6 border lg:flex-row border-gray-lightest" padding="p-3.5 sm:px-6 sm:py-4">
					<div class="flex flex-col gap-3.5 w-full lg:w-2/3">
						<div class="flex flex-wrap gap-3.5">
							<div class="flex flex-wrap gap-3.5 w-full lg:w-max">
								<div class="flex-none pr-4 sm:w-36 border-r border-gray-lightest">
									<label class="text-sm">Project ID</label>
									<h5 class="text-lg text-primary-darkest" >{{ project?.projectId ?? '-' }}</h5>
								</div>
								<div class="flex-none pr-4 border-0 sm:w-60 border-r border-gray-lightest">
									<label class="text-sm">No. {{project?.projectRequestType === 'RFP'?'RFP':'RFQ'}}</label>
									<h5 class="text-lg">{{ project?.noRequest ?? '-' }}</h5>
								</div>
								<div class="flex-1 w-full">
									<label class="text-sm">{{project?.projectRequestType === 'RFP'?'Project Name': 'Request Title'}}</label>
									<h5 class="text-lg">{{ project?.projectRequestType === 'RFP'? project?.projectName : project?.projectTitle }}</h5>
								</div>
							</div>
						</div>
						<div :class="`${windowWidth > 1600? '' : 'flex-col'} flex gap-3.5`">
							<div class="flex gap-3.5 flex-wrap ">
								<div class="flex gap-3.5 items-center pr-4 mb-2 border-r sm:mb-0 border-gray-lightest">
									<span class="text-xl icon-user"></span>
									<div class="">
										<label class="text-xs">Technical Coordinator</label>
										<h5>{{ project?.technicalCoordinatorName }}</h5>
									</div>
								</div>
								<div class="flex items-center gap-3.5 pr-4 mb-2 border-0 border-r sm:mb-0 border-gray-lightest">
									<span class="text-xl icon-calendar"></span>
									<div class="">
										<label class="text-xs">Last Edited</label>
										<h5>{{ project?.modifiedAt }}</h5>
									</div>
								</div>
							</div>
							<div class="flex gap-3.5 items-center">
								<span class="text-xl icon-clock"></span>
								<label class="text-sm">Current Status:</label>
								<LabelGista type="secondary"
									class="text-center text-white rounded-full">{{ project?.currentStatus }}</LabelGista>
							</div>
						</div>
					</div>
					<div class="flex flex-wrap-reverse w-full gap-3 lg:w-1/3 sm:gap-6 lg:justify-end"
						:class="{ 'flex-row flex-col-reverse': windowWidth < 640 }">
						<ButtonGista 
							v-if="menuRFP?.canUpdate"
							type="secondary"
							color="error"
							@click="changeIsOpen(true, 'modalDelete', project)"
							class="w-full lg:w-max"
							>
							Delete Draft
						</ButtonGista>
						<router-link class="w-full truncate lg:w-max" :to="`${project.projectRequestType === 'RFP'? `/${PATH_ROOT}/rfp/${project.projectRequestId}` : `/${PATH_ROOT}/rfq/${project.projectRequestId}`}`">
							<ButtonGista v-if="menuRFP?.canUpdate"
								type="primary"
								customClass="w-full">
								Continue Draft
							</ButtonGista>
						</router-link>
					</div>
				</Card>
				<ModalConfirm 
					title="Hapus Draft?" 
					message="Semua informasi akan dihapus, apakah Anda yakin?" 
					icon="rejected" 
					textButton="Delete Draft" 
					:isOpen="isOpenModalDeleteDraft" 
					@changeIsOpen="changeIsOpen($event, 'modalDelete')" 
					@click="deleteDraft(projectTemp.projectRequestId, projectTemp.projectRequestType)"
					colorButton="error"></ModalConfirm>
			</div>
			<div v-if="projectLogStatusDraft?.content?.length === 0 && !isLoading"
				class="flex flex-col items-center justify-center h-80">
				<img src="@/core/assets/icons/message/noitems.svg"
					alt="error"
					class="w-20" />
				<div class="mt-3">
					<p class="text-gray">Tidak Ada Data</p>
				</div>
			</div>
			<ProjectLoading v-if="isLoading"/>
		</div>
		<div v-if="projectLogStatusDraft?.content?.length > 0"
			class="flex justify-center">
			<PaginationGista :current="filterProject.currentPage"
				:total="filterProject.totalRecords"
				:per-page="+filterProject.pageCount"
				@page-changed="handlePageChange($event)"
				@paging-change="handlePagingChange($event)"></PaginationGista>
		</div>
	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import DatePickerCustom from '@/core/components/custom/DatePicker.vue';
import SelectDashboard from '@/core/components/custom/SelectDashboard.vue'
import { MENU_DASHBOARD, MENU_RFP, MENU_RFQ } from '@/core/constant/menuRole.js'
import { PATH_ROOT } from '@/core/constant/routeName'
import { MESSAGE_SUCCESS_DELETE_DRAFT } from '@/core/constant/successMessage'
import { formatDate } from '@/core/components/helpers/format.js'
import ProjectLoading from '@/core/components/dashboard/ProjectLoading.vue';
export default {
	name: "Draft",
	props: {
		windowWidth: Number,
	},
	data() {
		return {
			isLoading: false,
			timeout: null,
			PATH_ROOT,
			isOpenModalDeleteDraft: false,
			projectTemp: null
		}
	},
	computed: {
		maxDate() {
			return new Date()
		},
		projectLogStatusDraft: {
			get() {
				return this.$store.state.projectLog.projectLogStatusDraft
			},
			set(value) {
				this.$store.commit('projectLog/SET_PROJECT_LOG_STATUS_DRAFT', value)
			}
		},
		filterProject: {
			get() {
				return this.$store.state.dashboard.filterProject
			},
			set(val) {
				this.$store.commit('dashboard/SET_FILTER_PROJECT', val)
			}
		},
		menu() {
			return this.$store.state.dashboard.menu.find(e => e.menuId === MENU_DASHBOARD)
		},
		menuRFP() {
			return this.$store.state.dashboard.menu.find(e => e.menuId === MENU_RFP)
		},
		menuRFQ() {
			return this.$store.state.dashboard.menu.find(e => e.menuId === MENU_RFQ)
		},
		counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
	},
	watch: {
		projectLogStatusDraft: {
			handler() {
				this.updatePagination()
			},
			deep: true
		},
	},

	methods: {
		formatDate,
		async deleteDraft(id, type) {
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch(`${type === 'RFP'? 'deleteRfp/deleteRFP' : 'deleteRfq/deleteRFQ'}`, id)
				if (response?.status < 300 && response?.status >= 200) {
					//reset state proposal information
					this.modal.modalLoading = false
					await this.$store.dispatch('rfp/resetState')
					this.getProjectLogStatus()
					this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_DELETE_DRAFT }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		clickContinueDraft(id, type) {
			if (type === 'RFP') {
				this.$router.push(`/${PATH_ROOT}/rfp/${id}`).catch(() => ({}))
			} else {
				this.$router.push(`/${PATH_ROOT}/rfq/${id}`).catch(() => ({}))
			}
		},
		updateChange() {
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async routerPushQuery() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({query}).catch(() => ({}))
		},
		async handlePageChange(e) {
			this.filterProject.currentPage = e
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async handlePagingChange(e) {
			this.filterProject.pageCount = e
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async getProjectLogStatus() {
			this.isLoading = true
			const res = await this.$store.dispatch('projectLog/getProjectLogStatus', { projectRequestType: this.filterProject.type, projectStatus: 'draft', status: this.status, dateFrom: this.filterProject.formDate.start, dateTo: this.filterProject.formDate.end, anySearch: this.filterProject.search, page: this.filterProject.currentPage, sort: this.filterProject.sort, size: this.filterProject.pageCount })
			if (res?.status === 200) {
				this.isLoading = false
			}
		},
		updateDate(e) {
			this.filterProject.formDate = e
		},
		changeType(e) {
			this.filterProject.selectedType = e
			if (e === 'RFP') {
				this.filterProject.type = 'rfp'
			} else if (e === 'RFQ') {
				this.filterProject.type = 'rfq'
			} else {
				this.filterProject.type = ''
			}
		},
		changeSort(e) {
			if (e === 'Date Created: Newest') {
				this.filterProject.sort = 'createdAt,desc'
				this.filterProject.selectedSort = 'Date Created: Newest'
			} else if (e === 'Date Created: Oldest') {
				this.filterProject.sort = 'createdAt,asc'
				this.filterProject.selectedSort = 'Date Created: Oldest'
			}
		},
		searchBounce() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				this.updateChange()
			}, 500)
		},
		changeTypeWithResetPagination(e) {
			this.changeType(e)
			this.resetPagination()
			this.updateChange()
		},
		updateDateWithResetPagination(e) {
			this.updateDate(e)
			this.resetPagination()
			this.updateChange()
		},
		changeStatusWithResetPagination(e) {
			this.changeStatus(e)
			this.resetPagination()
			this.updateChange()
		},
		searchWithResetPagination() {
			this.searchBounce()
			this.resetPagination()
		},
		changeSortWithResetPagination(e) {
			this.changeSort(e)
			this.resetPagination()
			this.updateChange()
		},
		resetPagination() {
			this.filterProject.currentPage = 1
			this.filterProject.pageCount = 10
		},
		updatePagination() {
			this.filterProject.currentPage = this.projectLogStatusDraft.pageable?.pageNumber + 1
			this.filterProject.totalRecords = this.projectLogStatusDraft.totalElements
			this.filterProject.pageCount = this.projectLogStatusDraft.size
		},
		updateFilter() {
			if (this.$route.query.page) this.filterProject.currentPage = +this.$route.query.page
			if (this.$route.query.size) this.filterProject.pageCount = +this.$route.query.size
			if (this.$route.query.start || this.$route.query.end) this.updateDate({ start: this.$route.query.start, end: this.$route.query.end })
			if (this.$route.query.sort) this.changeSort(this.$route.query.sort)
			if (this.$route.query.search) this.filterProject.search = this.$route.query.search
			if (this.$route.query.type) this.changeType(this.$route.query.type)
		},
		changeIsOpen(val, key, project) {
			if (project) {
				this.projectTemp = project
			}
			if (key === 'modalDelete') {
				this.isOpenModalDeleteDraft = val
			}
		}
	},
	mounted() {
		clearInterval(this.counterInterval)
		this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
		this.updateFilter()
		this.getProjectLogStatus()
		this.updatePagination()
	},
	components: {
    Card,
		ModalConfirm,
    DatePickerCustom,
    SelectDashboard,
    ProjectLoading
}

}
</script>